<template>
  <div>
    <van-cell-group>
      <van-cell style="padding-top: 17px;padding-bottom: 17px;">
        <template #title>
          <span style="color:rgba(35, 171, 114, 1)">{{form.year+ form.teremName}}</span><br />
          <div class="custom-title hidden-text ">{{$route.query.schshiName}}</div>
        </template>
        <template #label>
          <div class="custom-centent  hidden-text">
            <svg-icon icon-class="date" size="15" /> 时间: {{form.createTime}}
          </div>
          <div class="custom-centent margin6 hidden-text">
            <svg-icon icon-class="unit" size="15" /> 等级: {{form.gradeName }}<span v-if="form.pbv"> （<span style="color:#ff976a;font-weight: 500;">¥{{form.pbv}}</span>） </span>
          </div>
        </template>
      </van-cell>
    </van-cell-group>

    <!-- 审核信息 -->
    <van-cell-group title="审核信息">
      <audit ref="audit" :app-params="{ coreKey: $route.query.id,  mkCode: 'JZ',coreCode:'GJZXJ' , status: form.spzt,level:3}" />
    </van-cell-group>

    <!-- 基本信息 -->
    <van-cell-group title="基本信息">
      <van-field v-model="form.stuNo" readonly label="学号" />
      <van-field v-model="form.stuName" readonly label="姓名" />
      <van-field v-model="form.sexName" readonly label="性别" />
      <van-field v-model="form.unitName" readonly label="院系" />
      <van-field v-model="form.specName" readonly label="专业" />
      <van-field v-model="form.className" readonly label="班级" />
      <van-field v-model="form.gradeNo" readonly label="年级" />
      <van-field v-model="form.enterMon" readonly label="入学年月" />
      <van-field v-model="form.nativePlaceName" readonly label="民族" />
      <van-field v-model="form.cardNo" readonly label="身份证号" />
      <van-field readonly v-model="form.telNo" label="联系电话" />
    </van-cell-group>

    <van-cell-group title="家庭经济情况">
      <van-field readonly v-model="form.famCnt" label="家庭人口总数" />
      <van-field readonly v-model="form.gmi" label="人均年收入" />
      <van-field readonly v-model="form.annualIncome" label="人均月收入" />
      <van-field readonly v-model="form.sor" label="收入来源" />
      <van-field readonly v-model="form.homeAddress" label="家庭住址" />
      <van-field readonly v-model="form.homePostcode" label="邮政编码" />
    </van-cell-group>

    <van-cell-group title="家庭成员情况">
      <van-collapse v-model="activeNames">
        <van-collapse-item v-for="item,index in form.jtcyxxList" :key="index" :title="item.name+'('+item.relation+')'" :name="index">
          <div> <span class="collapse-title">年龄:</span><span class="collapse-text">{{item.age}}</span></div>
          <div> <span class="collapse-title">工作（学习单位）:</span><span class="collapse-text">{{item.unitName}}</span></div>
        </van-collapse-item>
      </van-collapse>
      <div class="null-jtck" v-if="form.jtcyxxList.length ===0 ">无</div>
    </van-cell-group>
    <!-- 申请理由 -->
    <van-cell-group title="申请理由">
      <van-field v-model="form.applyReason" readonly rows="3" class="textarea-style" arrow-direction="" label="申请理由" type="textarea" maxlength="300" show-word-limit />
    </van-cell-group>

    <van-cell-group title="证明附件" class="upload-file" style="margin-bottom:65px;">
      <file-upload v-if="refsh" :preview="true" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" v-model="form.fileId" miz="JZGL" biz="storage" :multiple="3" list-type="list" :auto-upload="true" :max-size="1">
        <span slot="tip" class="upload-tips">
          支持上传任意文件,文件最大1M
        </span>
      </file-upload>
    </van-cell-group>

    <!-- <div class="submit-button shadow-style">
      <div>
        <van-button round block type="warning" :loading="loading" @click="doDownload()">{{"下载"+(form.year?form.year+'年':"")+'国家助学金申请'}}</van-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getGjzxjById, downloadPdf } from "@/api/modules/award/gjzxj";
import { statustag, parseTime } from "@/utils";
import fileUpload from "@/components/FileUpload";
import audit from "@/view/modules/common/audit";
import { simulationClickBlob } from "@/api/system/storage";

export default {
  name: "NationDetail",
  components: { fileUpload, audit },
  dicts: ["xj_shzt"],
  data() {
    return {
      activeNames: [0],
      loading: false,
      refsh: true,
      form: {
        id: "",
        year: new Date().getFullYear().toString(),
        schCode: "13954",
        // 学校名称
        schName: "甘肃农业职业技术学院",
        // 院系代码
        unitNo: "",
        // 院系名称
        unitName: "",
        // 专业代码
        specCode: "",
        // 专业名称
        specName: "",
        // 年级
        gradeNo: "",
        // 班级号
        classNo: "",
        // 班级名称
        className: "",
        // 班主任工号
        classTchStaffNo: "",
        // 班主任姓名
        classTchStaffName: "",
        // 辅导员号
        conslrStaffNo: "",
        // 辅导院姓名
        conslrStaffName: "",
        // 班长号
        monitrStuNo: "",
        // 班长姓名
        monitrStuName: "",
        // 入学年月
        enterMon: "",
        // 学号
        stuNo: "",
        // 姓名
        stuName: "",
        // 性别名称
        sexName: "",
        // 出生日期
        bdDate: "",
        // 籍贯地码
        nativePlaceNo: "",
        // 籍贯地码
        nativePlace: "",
        // 身份证号
        cardNo: "",
        // 家庭人口数
        famCnt: "",
        // 联系电话
        telNo: "",
        // 详细家庭地址
        homeAddress: "",
        // 详细家庭地址
        nativePlaceName: "",
        //民族
        polName: "",
        //政治面貌
        gmi: "", //月总收入
        // 人均年收入
        annualIncome: "",
        // 收入来源
        sor: "",
        // 个人承诺
        applyReason: "",
        // 审批状态
        spzt: "",
        jtcyxxList: [],
        status: 0,
        fileId: [],
      },
    };
  },
  created() {
    getGjzxjById({ id: this.$route.query.id }).then((res) => {
      this.form = res;
      if (res.fileId) {
        this.form.fileId = res.fileId;
      }

      this.refsh = false;
      this.$nextTick(() => {
        this.refsh = true;

        setTimeout(() => {
          // 业务ID 审批项目类型 审批业务类型 目前到审批状态
          this.$refs["audit"].init(
            res.id,
            "JZ",
            "GJZXJ",
            parseInt(res.spzt),
            3
          );
        }, 100);
      });
    });
  },
  methods: {
    statustag,
    parseTime,
    doDownload() {
      this.loading = true;
      downloadPdf(this.form.id)
        .then((result) => {
          simulationClickBlob(
            result,
            this.form.stuName + "-国家助学金申请表.pdf"
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    codeConvertLabel(code, dicts) {
      return dicts
        .filter((item) => {
          return item.value + "" === code + "";
        })
        .map((item) => {
          return item.label;
        })
        .toString();
    },
    getSpztLabel(shzt) {
      let approvalStatus = parseInt(shzt);
      return this.dict.xj_shzt
        .filter((item) => {
          return parseInt(item.value) === approvalStatus;
        })
        .map((item) => {
          return item.label;
        })
        .toString();
    },
  },
};
</script>


<style lang="scss" scoped>
#Form {
  .van-cell {
    font-size: 15px;
    line-height: 32px;
    height: 50px;
  }
  .van-cell__left-icon,
  .van-cell__right-icon {
    line-height: 32px;
  }
  .textarea-style {
    height: 100% !important;
  }
}
.audit {
  top: 17px;
  right: 10px;
  border-radius: 0 13px 0 13px;
}
.custom-title {
  margin: 0 0 7px 0;
}
.null-jtck {
  padding: 15px 15px;
}
</style>